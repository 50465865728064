import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 营销活动
export function fetchCampaignCalendar(data) {
	return request({
		url: '/seller/activity/dashuCalendar',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCampaignList(data) {
	return request({
		url: '/seller/activity/page',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCampaignInfo(data) {
	return request({
		url: '/seller/activity/store/show',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCampaignGoods(data) {
	return request({
		url: '/seller/activity/goods/can/enroll/page',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchEntryGoods(data) {
	return request({
		url: '/seller/activity/goods/enroll/page',
		method: 'post',
		data: param2Obj(data)
	})
}

export function signUpShop(data) {
	return request({
		url: '/seller/activity/store/enroll',
		method: 'post',
		data: param2Obj(data)
	})
}

export function signUpGoods(data) {
	return request({
		url: '/seller/activity/goods/do/enroll',
		method: 'post',
		data: param2Obj(data)
	})
}

export function uploadCampaignGoods(data) {
	return request({
		url: '/seller/activity/goods/update',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCampaignTags(data) {
	return request({
		url: '/seller/activity/label/list',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCampaignRecords(data) {
	return request({
		url: '/seller/activity/store/page',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCampaignSignItems(data) {
	return request({
		url: '/seller/activity/goods/page',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchTreasuresList(data) {
	return request({
		url: '/seller/single/treasure/page',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchTreasuresItems(data) {
	return request({
		url: '/seller/single/treasure/goods/pageJoins',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchTreasuresInfo(data) {
	return request({
		url: '/seller/single/treasure/detail',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchTreasuresTags(data) {
	return request({
		url: '/seller/single/treasure/labels',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchTreasuresRegions(data) {
	return request({
		url: '/seller/single/treasure/regions',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchTreasuresCategory(data) {
	return request({
		url: '/seller/single/treasure/goods/listStoreCate',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchTreasuresGoods(data) {
	return request({
		url: '/seller/single/treasure/goods/pageSelGoods',
		method: 'post',
		data: param2Obj(data)
	})
}

export function createTreasures(data) {
	return request({
		url: '/seller/single/treasure/add',
		method: 'post',
		data: param2Obj(data)
	})
}

export function updateTreasures(data) {
	return request({
		url: '/seller/single/treasure/edit',
		method: 'post',
		data: param2Obj(data)
	})
}

export function deleteTreasures(data) {
	return request({
		url: '/seller/single/treasure/delete',
		method: 'post',
		data: param2Obj(data)
	})
}

export function restartTreasures(data) {
	return request({
		url: '/seller/single/treasure/restart',
		method: 'post',
		data: param2Obj(data)
	})
}

export function suspendTreasures(data) {
	return request({
		url: '/seller/single/treasure/suspend',
		method: 'post',
		data: param2Obj(data)
	})
}

export function saveTreasuresGoods(data) {
	return request({
		url: '/seller/single/treasure/goods/saveItem',
		method: 'post',
		data: param2Obj(data)
	})
}

export function updateTreasuresGoods(data) {
	return request({
		url: '/seller/single/treasure/goods/update',
		method: 'post',
		data: param2Obj(data)
	})
}

export function deleteTreasuresGoods(data) {
	return request({
		url: '/seller/single/treasure/goods/delete',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchTreasuresTrend(data) {
	return request({
		url: '/seller/single/treasure/statistics',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchTreasuresChart(data) {
	return request({
		url: '/seller/single/treasure/statisticsDetail',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchTreasuresTrendList(data) {
	return request({
		url: '/seller/single/treasure/statisticsActivity',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCombomealList(data) {
	return request({
		url: '/seller/collocation/treasure/page',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCombomealItems(data) {
	return request({
		url: '/seller/collocation/treasure/goods/page',
		method: 'post',
		data: param2Obj(data)
	})
}

export function deleteCombomealItem(data) {
	return request({
		url: '/seller/collocation/treasure/goods/remove',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCombomealGoods(data) {
	return request({
		url: '/seller/collocation/treasure/goods/pageSel',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCombomealInfo(data) {
	return request({
		url: '/seller/collocation/treasure/detail',
		method: 'post',
		data: param2Obj(data)
	})
}

export function createCombomeal(data) {
	return request({
		url: '/seller/collocation/treasure/add',
		method: 'post',
		data: param2Obj(data)
	})
}

export function updateCombomeal(data) {
	return request({
		url: '/seller/collocation/treasure/edit',
		method: 'post',
		data: param2Obj(data)
	})
}

export function suspendCombomeal(data) {
	return request({
		url: '/seller/collocation/treasure/suspend',
		method: 'post',
		data: param2Obj(data)
	})
}

export function restartCombomeal(data) {
	return request({
		url: '/seller/collocation/treasure/restart',
		method: 'post',
		data: param2Obj(data)
	})
}

export function deleteCombomeal(data) {
	return request({
		url: '/seller/collocation/treasure/delete',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCombomealData(data) {
	return request({
		url: '/seller/collocation/treasure/index',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCombomealTrend(data) {
	return request({
		url: '/seller/collocation/treasure/statistics',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCombomealChart(data) {
	return request({
		url: '/seller/collocation/treasure/statisticsDetail',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCombomealTrendList(data) {
	return request({
		url: '/seller/collocation/treasure/statisticsActivity',
		method: 'post',
		data: param2Obj(data)
	})
}
